const maxSnapPixelDistance = 15;

export const getSnapPoint = (
  snapper: Autodesk.Viewing.Extensions.Snapping.Snapper,
  viewer: Autodesk.Viewing.Viewer3D
): THREE.Vector3 => {
  const snapResult = snapper.getSnapResult();
  const { SnapType } = Autodesk.Viewing.MeasureCommon;

  switch (snapResult.geomType) {
    case SnapType.SNAP_VERTEX:
    case SnapType.SNAP_MIDPOINT:
    case SnapType.SNAP_INTERSECTION:
    case SnapType.SNAP_CIRCLE_CENTER:
    case SnapType.RASTER_PIXEL:
    case SnapType.SNAP_EDGE:
    case SnapType.SNAP_CIRCULARARC:
    case SnapType.SNAP_CURVEDEDGE: {
      const vertexPixelPos = Autodesk.Viewing.MeasureCommon.project(
        snapResult.geomVertex,
        viewer
      );
      const intersectionPixelPos = Autodesk.Viewing.MeasureCommon.project(
        snapResult.intersectPoint,
        viewer
      );

      const distance = new THREE.Vector2(
        vertexPixelPos.x,
        vertexPixelPos.y
      ).distanceTo(
        new THREE.Vector2(intersectionPixelPos.x, intersectionPixelPos.y)
      );
      const isWithinPixelDistance = distance <= maxSnapPixelDistance;

      if (isWithinPixelDistance) {
        return snapResult.geomVertex;
      }

      return snapResult.intersectPoint;
    }
    default:
      return snapResult.intersectPoint;
  }
};
