import { useEffect, useMemo } from 'react';

export function useSnapper(viewer?: Autodesk.Viewing.Viewer3D | null) {
  const snapper = useMemo(
    () =>
      viewer
        ? new Autodesk.Viewing.Extensions.Snapping.Snapper(viewer, {
            renderSnappedGeometry: false,
            renderSnappedTopology: false,
            forceSnapEdges: true,
            forceSnapVertices: true,
            toolName: 'Snapper',
          })
        : null,
    [viewer]
  );

  useEffect(() => {
    if (viewer && snapper) {
      viewer.toolController.registerTool(snapper);
      viewer.toolController.activateTool(snapper.getName());
    }

    return () => {
      if (viewer && snapper) {
        viewer.toolController.deactivateTool(snapper.getName());
        viewer.toolController.registerTool(snapper);
      }
    };
  }, [viewer, snapper]);

  return { snapper };
}
