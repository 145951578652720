/* eslint-disable complexity */
import { Auth, CognitoUser } from '@aws-amplify/auth';
import * as Sentry from '@sentry/react';
import {
  useUnleashClient,
  useUnleashContext,
} from '@unleash/proxy-client-react';
import { print } from 'graphql';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Navigate,
  useLocation,
  useMatches,
  useNavigate,
  useParams,
} from 'react-router-dom';
import invariant from 'tiny-invariant';
import { Provider as UrqlProvider } from 'urql';
import MissingTenantPage from '../pages/MissingTenantPage';

import { usePlausibleClient } from '../analytics';
import awsExports from '../aws-exports';
import { ListContractorsDocument } from '../gql/graphql';
import { createUrqlClient } from '../urql';
import { parseExternalAccessToken } from '../utils/jwt-utils';
import { useMixpanel } from './mixpanel';

export type UserTenant = {
  name: string;
  group: string | null | undefined;
};

export enum UserType {
  Contractor = 'contractor',
  External = 'external',
}

export type UserInfo = {
  userId?: string;
  username: string;
  displayName?: string;
  userType: UserType;
  isAdmin: boolean;
  contractorName: string | undefined;
  contractorGroup: string | undefined;
  tenantTier: string;
  scope?: {
    projectId: string;
    orderIds: string[];
  };
  signOut: () => void;
};

export type TenantContextType = {
  tenant: UserTenant | null;
  username: string;
  isAdmin: boolean;
  isProtan: boolean;
  tenantTier: string;
  signOut: () => void;
  userDetails: {
    userId?: string;
    displayName: string;
    userType: UserType;
  };
} | null;

type Handle =
  | {
      showInReadonlyContext?: boolean;
    }
  | undefined;

const TenantContext = createContext<TenantContextType>(null);

const EMAIL_PATTERN = /^[\w-.]+(\+[\w-.]+)?@([\w-]+\.)+[\w-]{2,4}$/;

// eslint-disable-next-line complexity
export function AuthInfoProvider({ children }: { children: React.ReactNode }) {
  const updateUnleashContext = useUnleashContext();
  const unleashClient = useUnleashClient();
  const location = useLocation();
  const { identify } = useMixpanel();

  const navigate = useNavigate();
  const matches = useMatches();
  const params = useParams();
  const [user, setUser] = useState<UserInfo | null>(null);
  let graphqlEndpoint: string;
  if (!awsExports.graphql_endpoint) {
    throw new Error('Missing graphql endpoint');
  } else {
    graphqlEndpoint = awsExports.graphql_endpoint;
  }
  useEffect(() => {
    const isSharedView = location.pathname.startsWith('/public/view/');

    const resolve = async () => {
      if (isSharedView) {
        return;
      }
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        const session = user.getSignInUserSession();
        if (session === null) {
          throw new Error('User is not authenticated');
        }

        const idToken = session.getIdToken();
        const jwtToken = idToken.getJwtToken();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const username: string = idToken.payload['email']!;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const userId = idToken.payload['sub']!;
        const displayName = idToken.payload['custom:displayName'] ?? null;
        const contractorGroup: string | undefined =
          idToken.payload['cognito:groups']?.[0];
        const isAdmin = idToken.payload['custom:isAdmin'] === 'true';

        let fetchUrl: string;
        if (process.env.NODE_ENV === 'development') {
          fetchUrl = `${graphqlEndpoint}?operationName=ListContractors`;
        } else {
          fetchUrl = graphqlEndpoint;
        }

        try {
          // Using fetch instead of the urql client here because the client is not yet initialized
          const contractorResponse = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'Accept-Encoding': 'gzip, deflate, br',
              Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify({
              query: print(ListContractorsDocument),
            }),
          }).then((response) => {
            if (!response.ok) {
              throw new Error('Failed to fetch contractors');
            } else {
              return response.json();
            }
          });

          const contractorName: string =
            contractorResponse.data.contractors[0]?.name;

          const tenantTier: string =
            contractorResponse.data.contractors[0]?.contractorTier;

          setUser({
            userId,
            username,
            contractorName,
            displayName,
            contractorGroup,
            isAdmin,
            tenantTier,
            userType: UserType.Contractor,
            signOut: () => {
              Auth.signOut().then(() => {
                setUser(null);
                navigate('/login', { replace: true });
              });
            },
          });

          // Mixpanel id
          if (userId && contractorName) {
            identify({ userId, email: username }, contractorName);
          }
        } catch (error) {
          setUser(null);
        }
      } catch (error) {
        const accessToken = localStorage.getItem('external_access_token');
        if (accessToken) {
          const tokenPayload = parseExternalAccessToken(accessToken);

          if (tokenPayload !== null) {
            const {
              contractorName,
              orderOwner,
              displayName,
              orderIds,
              projectId,
            } = tokenPayload;

            // Check if the order still exists. If it doesn't, we regard this as a revokation, and remove the temporary access token
            let fetchUrl: string;
            if (process.env.NODE_ENV === 'development') {
              fetchUrl = `${graphqlEndpoint}?operationName=GetOrder`;
            } else {
              fetchUrl = graphqlEndpoint;
            }

            try {
              const orderResponse = await fetch(fetchUrl, {
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  query: /* GraphQL */ `
                    query GetOrder($id: UUID!) {
                      order(id: $id) {
                        id
                      }
                    }
                  `,
                  variables: {
                    id: orderIds[0],
                  },
                }),
              }).then((response) => {
                if (!response.ok) {
                  throw new Error('Failed to fetch order');
                } else {
                  return response.json();
                }
              });

              if (orderResponse.data.order === null) {
                localStorage.removeItem('external_access_token');
                navigate('/login?expired=true', { replace: true });
                return;
              }
            } catch (error) {
              navigate('/login?expired=true', { replace: true });
              setUser(null);
              return;
            }

            const username = displayName ?? `${contractorName} - External`;

            setUser({
              // NOTE: This is not an actual username, as an external user does not have an email
              username,
              contractorName,
              contractorGroup: orderOwner,
              displayName,
              userType: UserType.External,
              //HMM
              tenantTier: 'FREE',
              isAdmin: false,
              scope: {
                projectId,
                orderIds,
              },
              signOut: () => {
                localStorage.removeItem('external_access_token');
                navigate('/login', { replace: true });
              },
            });
          }
        } else {
          setUser(null);
          navigate('/login', { replace: true });
        }
      }
    };
    if (user === null) {
      resolve();
    }
  }, [graphqlEndpoint, identify, location.pathname, navigate, user]);

  const plausibleClient = usePlausibleClient();

  useEffect(() => {
    const tenant = user?.contractorGroup;

    if (tenant) {
      plausibleClient.trackPageview(
        {
          url: `${window.location.origin}${location.pathname}`,
          trackLocalhost: false,
        },
        {
          props: {
            tenant,
          },
        }
      );
    }
  }, [location, plausibleClient, user?.contractorGroup]);

  const isAuthenticated = user !== null;

  const urqlClient = useMemo(() => {
    if (!isAuthenticated && !location.pathname.startsWith('public/view/')) {
      return null;
    } else {
      return createUrqlClient();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (user) {
      const username = user.username;
      const userGroup = user.contractorGroup;
      const projectId: string | undefined = params.projectId;

      Sentry.setUser({ email: username });
      Sentry.setContext('tenant', { name: userGroup });
      Sentry.setContext(
        'feature-toggles',
        Object.fromEntries(
          unleashClient
            .getAllToggles()
            .map((toggle) => [toggle.name, toggle.enabled])
        )
      );

      if (userGroup) {
        let properties: Record<string, string> = {
          tenant: userGroup,
          // Keeping groups for backwards compatibility
          groups: userGroup,
        };

        if (projectId) {
          properties.projectId = projectId;
        }

        updateUnleashContext({
          userId: username,
          properties,
        });
      }
    }
  }, [unleashClient, updateUnleashContext, user, params]);

  const context: TenantContextType = useMemo(() => {
    if (user) {
      return {
        tenant: user.contractorGroup
          ? {
              name: user.contractorName ?? user.contractorGroup,
              group: user.contractorGroup,
            }
          : null,
        username: user.username,
        isAdmin: user.isAdmin,
        isProtan: user.contractorGroup === 'Protan',
        tenantTier: user.tenantTier,
        userDetails: {
          userId: user.userId,
          displayName: user.displayName ?? user.username,
          userType: user.userType,
        },
        signOut: user.signOut,
      };
    } else {
      return null;
    }
  }, [user]);

  if (user === null) {
    console.debug('missing user');
    return null;
  } else if (!user.contractorName) {
    return (
      <TenantContext.Provider value={context}>
        <MissingTenantPage />;
      </TenantContext.Provider>
    );
  } else if (
    // Since "external" users shouldn't be "poking around" outside the takeoff page, take them there
    user.scope &&
    !matches.some((match) => (match.handle as Handle)?.showInReadonlyContext)
  ) {
    return (
      <Navigate
        to={`/project/${user.scope.projectId}/takeoff/${user.scope.orderIds[0]}`}
        replace={true}
      />
    );
  } else {
    invariant(urqlClient, 'urqlClient should be defined here');
    return (
      <TenantContext.Provider value={context}>
        <UrqlProvider value={urqlClient}>{children}</UrqlProvider>
      </TenantContext.Provider>
    );
  }
}

export const useUserTenant = () => {
  const context = useContext(TenantContext);
  if (context === null) {
    throw new Error('useUserTenant must be used within a logged in user');
  }

  return context;
};
