import { useCallback } from 'react';
import { useClient } from 'urql';
import {
  ForgeAccessToken,
  GetForgeAccessTokenDocument,
  ModelsDeepFragment,
} from '../gql/graphql';

export const useForgeAccessToken = (
  projectId: string,
  activeModels: ModelsDeepFragment[]
) => {
  const client = useClient();

  const getToken = useCallback(
    async (): Promise<ForgeAccessToken | null> => {
      const result = await client.query(
        GetForgeAccessTokenDocument,
        {
          projectId,
        },
        {
          requestPolicy: 'network-only',
        }
      );
      if (!result.data?.forgeAccessToken) {
        if (result.error) {
          return null;
        } else {
          return {
            __typename: 'ForgeAccessToken',
            accessToken: 'foo',
            expiresIn: 3600,
          };
        }
      } else {
        return result.data.forgeAccessToken;
      }
    },
    // Get a new token when active models changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client, projectId, activeModels]
  );

  const getAccessToken = useCallback(
    (callback?: (accessToken: string, expires: number) => void): void => {
      if (typeof callback !== 'function') return;

      getToken()
        .then((token) => {
          if (!token) throw new Error('No token returned from server');

          callback(token.accessToken, token.expiresIn);
        })
        .catch((error) => console.error('Error fetching access token', error));
    },
    [getToken]
  );

  return { getToken, getAccessToken };
};
