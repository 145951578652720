import { ActiveTool } from 'src/components/viewers/SheetsViewerV2/enums/ActiveTool';
import { AggregatedView } from 'src/components/viewers/SheetsViewerV2/hooks/useSheetsAggregatedView';
import { ISheetShape } from 'src/components/viewers/SheetsViewerV2/shapes/abstract/AbstractSheetShape';
import {
  CROSSHAIR_CURSOR,
  VIEWER_TOP_OFFSET,
} from 'src/components/viewers/SheetsViewerV2/SheetsViewerV2.config';

type DbId = number;

export class Edit2DSelectTool extends Autodesk.Viewing.ToolInterface {
  names = [ActiveTool.Select];

  private _hovered: ISheetShape | null = null;
  private _selected: Map<DbId, ISheetShape> = new Map();

  constructor(
    private readonly viewer: AggregatedView['viewer'],
    private readonly shapes: ISheetShape[],
    private readonly onSelect?: (shape: ISheetShape[]) => void,
    private readonly onHover?: (shape: ISheetShape | null) => void
  ) {
    super();

    delete (this as any).handleSingleClick;
    delete (this as any).handleMouseMove;
  }

  getCursor(): string {
    return CROSSHAIR_CURSOR;
  }

  handleSingleClick(event: MouseEvent, button: number): boolean {
    const clientX = event.clientX;
    const clientY = event.clientY - VIEWER_TOP_OFFSET;
    const cursorPosition = this.viewer.impl.intersectGround(clientX, clientY);

    if (button !== 0 || !cursorPosition) return false;

    const hitShape =
      this.shapes.find((shape) =>
        shape.hitTest(cursorPosition.x, cursorPosition.y)
      ) || null;

    if (event.shiftKey && !hitShape) {
      return false;
    } else if (event.shiftKey && hitShape) {
      if (this._selected.has(hitShape.dbId)) {
        hitShape.setSelected(false);
        this._selected.delete(hitShape.dbId);
      } else {
        hitShape.setSelected(true);
        this._selected.set(hitShape.dbId, hitShape);
      }
      this.onSelect?.([...this._selected.values()]);
    } else if (hitShape) {
      this._selected.forEach((selected) => selected.setSelected(false));
      this._selected = new Map([[hitShape.dbId, hitShape]]);
      this.onSelect?.([...this._selected.values()]);
    } else {
      this._selected.forEach((selected) => selected.setSelected(false));
      this._selected = new Map();
      this.onSelect?.([...this._selected.values()]);
    }

    return true;
  }

  handleMouseMove(event: MouseEvent): boolean {
    const clientX = event.clientX;
    const clientY = event.clientY - VIEWER_TOP_OFFSET;
    const cursorPosition = this.viewer.impl.intersectGround(clientX, clientY);

    if (!cursorPosition) {
      return false;
    }

    const hitShape =
      this.shapes.find((shape) =>
        shape.hitTest(cursorPosition.x, cursorPosition.y)
      ) || null;

    if (hitShape !== this._hovered) {
      this._hovered?.setHovered(false);
      this._hovered = hitShape;
      hitShape?.setHovered(true);
      this.onHover?.(hitShape);
    }

    return false; // When set to true, smooth panning is disabled.
  }
}
